<template>
  <div>
    <header id="header" class="container-fluid header col-12">
      <div class="d-flex col-12" style="align-items: center !important; padding-top: 5px;">
        <div class="d-flex col-6 align-items-center" style="justify-content: flex-start;">      
          <div>
            <router-link :to="{ handleLogo}">
              <div class="header-partner-logo">
                <img :src="partnerImgSrc" alt="logo" class="img-auto" />
              </div>
            </router-link>     
          </div>
        </div>
        <div class="d-flex col-6 align-items-center" style="justify-content: flex-end;">
          <div v-if="getShowClaroFieldsComputed">
            <a @click="openRequests">
              <router-link to="">
                <div class="p-1"> 
                  <img :src="partnerIconSrc" alt="icon" class="img-auto" />
                </div>
              </router-link>    
            </a>
          </div>
          <div v-if="!hideIcon">
            <a @click="openCart">
              <router-link to="">
                <div> 
                  <img :src="partnerIconBySrc" alt="icon" class="img-auto" />
                </div>
              </router-link>    
            </a>
          </div>
          <b-badge variant="danger" v-show="getCartLengthComputed">
            {{ getCartLengthComputed }} <span class="sr-only"></span>
          </b-badge>
        </div>
      </div>
    </header>
  </div>
  <template>
    <Login v-if="showModal" @close="toggleModal" />
  </template>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { myOrders } from "@/modules/customer/services/orders.service.js";
import Login from "@/components/Login/Login.vue";
import productTypesOptionsConstants from "@/constants/sale/productTypesOptions.constants";
import Configuration from "@/services/configuration";
import RegionalizationModal from "../Regionalization/RegionalizationModal.vue";
export default {
  name: "Header",
  props: {
    login: {
      type: Function,
      default: () => {},
    },
    hideIcon: {
      type: Boolean,
      default: false
    },
    logoRedirectUrl: {
      type: String,
    }
  },
  data() {
    return {
      partnerIconBySrc: null,
      partnerIconSrc: null,
      partnerImgSrc: null,
      currentPage: 1,
      showModal: false
    };
  },
  components: {
    Login
  },
  computed: {
    ...mapGetters({
      getPartnerConfigurationComputed: "getPartnerConfiguration",
      getCartLengthComputed: "cart/getCartLength",
      hasAvailableProductTypeClaroComputed: "saleFlow/product/hasAvailableProductTypeClaro",
      getShowClaroFieldsComputed: "address/getShowClaroFields",
      getCustomerComputed: "customer/getCustomer",
      authenticateUserComputed: "auth/AuthorizationCustomer",
      getOrdersComputed: "saleFlow/getOrders",
      getSaleOfServiceComputed: "saleFlow/getSaleOfService",
      getCurrentAddressComputed: 'cart/getCurrentAddress'
    }),
    stringAddress() {
      return this.getCurrentAddressComputed?.streetName + ", " + this.getCurrentAddressComputed?.cityName + ", " + this.getCurrentAddressComputed?.stateName;
    }
  },
  watch: {
    getPartnerConfigurationComputed() {
      this.setPartnerConfigurationLogoSrc();
      this.setPartnerConfigurationIconSrc();
      this.setPartnerConfigurationIconBySrc();
    }
  },
  mounted() {
    this.setPartnerConfigurationLogoSrc();
    this.setPartnerConfigurationIconSrc();
    this.setPartnerConfigurationIconBySrc();  
  },
  methods: {
    setPartnerConfigurationLogoSrc() {
      this.getPartnerConfigurationComputed
        ?.logo()
        .then((module) => (this.partnerImgSrc = module?.default));
    },
    setPartnerConfigurationIconSrc() {
      this.getPartnerConfigurationComputed
        ?.icon() 
        .then((module) => (this.partnerIconSrc = module?.default));
    },
    setPartnerConfigurationIconBySrc() {
      this.getPartnerConfigurationComputed
        ?.iconBy() 
        .then((module) => (this.partnerIconBySrc = module?.default));
    },
    ...mapActions({
      openCartAction: "cart/openCart",
      openRequestAction: "cart/openRequest",
      authenticateUserAction: "auth/authenticateUser",
      refreshOrdersAction: "saleFlow/refreshOrders",
      getAllProductsCatalogAction: 'saleFlow/getAllProductsCatalog',
      setAvailableProductTypeAction: 'saleFlow/product/setAvailableProductType',
      openRegionalizationModalAction: 'address/openRegionalizationModal',
      setRegionalizationModalCallbackAction: 'address/setRegionalizationModalCallback',
      setShowClaroFieldsAction: 'address/setShowClaroFields',
      clearCurrentAddressAction: 'address/clearCurrentAddress',
    }),
     handleLogo() {
      if (this.logoRedirectUrl) {
        return this.$router.push({ path: this.logoRedirectUrl })
      }
    },
    getProducts() {
      this.ClaroFieldsAction();
      this.setAvailableProductTypeAction([
        Configuration.productTypeOption[productTypesOptionsConstants.CLARO_RESIDENTIAL_TV],       
      ])
      .then(() => this.getAllProductsCatalogAction())
    },
    startRegionalizationFlow () {
      if (this.getCurrentAddressComputed?.id === null || this.getCurrentAddressComputed?.id === undefined) {
        this.getProducts();
        return;
      }
      this.openRegionalizationModalAction();
      this.setRegionalizationModalCallbackAction(() => {
        this.getProducts();
      });
    },
    ClaroFieldsAction(){
      this.setShowClaroFieldsAction();
    },
    McafeeStaticAction() {
      this.setShowMcafeeStaticAction();
    },
    openCart() {
      this.openCartAction();
    },
    openRequests() {
      if (this.isAuthenticateUserComputed === false) {
        this.$swal(
          "Para acessar seus pedidos, é necessário estar logado.",
          " ",
          "warning"
        );
        this.showModal = true;
        return;
      }
      this.openRequestAction().then(() => {
        this.orders();
        if (
          this.getSaleOfServiceComputed?.id === null ||
          this.getSaleOfServiceComputed?.id === undefined
        ) {
          this.$swal("Você não possui pedidos ativos.", " ", "info");
        }
        this.refreshOrdersAction();
      });
    },
    async orders() {
      try {
        const currentPage = this.currentPage;
        const response = await myOrders(currentPage);
      } catch (error) {
        this.$swal(
          "Ocorreu um erro de processamento, por favor, tente novamente.",
          " ",
          error
        );
        this.$swal("Erro ao buscar pedidos:", error);
      }
    },
    changeAddress() {
      this.clearCurrentAddressAction(this.getCurrentAddressComputed);
      this.startRegionalizationFlow();
      return;
    }
  }
};
</script>

<style lang="scss" scoped>
.text-user {
  font-size: 11px;
  font-weight: 700;
  color: rgb(148, 26, 4);
  text-align: center;
  line-height: 1.2;
}
.target {
  background-color: #e3262e;
  color:white; 
  font-size: 11px; 
  font-weight: 700;
}
.header-partner-logo{
  margin-top: 7px;
}
@media (min-width: 320px) and (max-width: 768px) {
  .header-partner-logo{
  margin-top: 5px;
}
}
</style>
